import React, {
  useRef,
  useContext,
  useState,
  useEffect
} from 'react'
import { navigate } from 'gatsby'
import { sContext } from '../../../../context'
import CityGuidesList from '../../CityGuidesList'

import { CloseButton, EnFrButton, SearchButton } from './NavigationHelpers'

const SearchNavigation = ({
  isSearchNavType,
  guides,
  activateNavType,
  getLanguageChange,
}) => {
  const searchRef = useRef()
  const localePath = useContext(sContext).currentLocale
  const location = useContext(sContext).location
  const [searchedGuides, setSearchedGuides] = useState(guides || [])

  const setTag = (event) => {
    setSearchedGuides(
      guides.filter(({ fields: { title }}) => {
        return title
          .split(' - ')[1]
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    }))
  }

  const handleGoToSearch = (value) => {
    navigate(`/${localePath}/search?q=${encodeURIComponent(value)}`)

    if(
      typeof window !== 'undefined' &&
      (location.href.includes('/search?q=') || location.href.includes('/search/?q='))
    ) {
      window.location.reload()
    }
  }

  const goToSearch = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') { handleGoToSearch(event.target.value) }
  }

  const goToSearchButton = (event) => {
    if (event !== '') { handleGoToSearch(event) }
  }

  useEffect(() => {
    if(isSearchNavType) {
      searchRef.current.focus()
    }
  }, [isSearchNavType])

  return (
    <div className="col-lg-12">
      <div className="row">
        <div className="searchbar-container col-lg-4 offset-8">
          <div className="globalnav-tools-container">
            <div className="row globalnav-tools white-white">
              <SearchButton
                ariaId='search-button-3'
                close
                localePath={localePath}
                activateNavType={activateNavType} />
              <EnFrButton
                english='EN'
                french='FR'
                idNumber='4'
                getLanguageChange={getLanguageChange}
                localePath={localePath} />
              <CloseButton
                ariaId='close-button-3'
                ariaTitle={localePath === 'en' ?
                  'Close Menu Window' : 'Fermer la fenêtre du menu'}
                  activateNavType={activateNavType} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="row globalnav-search-bar">
            <div className="globalnav-search-icon" />
            <input
              ref={searchRef}
              className="globalnav-search-input"
              onKeyPress={goToSearch}
              onChange={setTag}
              role="search"
              aria-label={localePath === 'en' ? 'Search for Articles' : 'Rechercher des articles'} />
            <div
              className="globalnav-search-arrow"
              onClick={() => goToSearchButton(searchRef.current.value) } />
          </div>
          <CityGuidesList
            text={(localePath === 'en' && 'City Guides') || 'Guides de la ville'}
            cityguides={searchedGuides}
          />
        </div>
      </div>
    </div>
  )
}

export default SearchNavigation