import React, { useContext } from 'react';
import ReactDOMServer from 'react-dom/server'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import { ARIA_EXTERNAL } from './AnchorTag';
import { sContext } from '../../context';
import ReactMarkdown from '../../lib/ReactMarkdown';
import { decode } from 'he'

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, next) => {
      const {
        data: {
          uri: url
        }
      } = node;
      const [ value ] = next;
      const localePath = useContext(sContext).currentLocale;

      return `
        <a
          ${url.includes('://') ? `
            target="_blank"
            rel="noopener noreferrer"
            aria-label="${value}${ARIA_EXTERNAL[localePath]}"
            href="${url}"
          ` : `
            href="/${localePath}/article/${url}"
          `} >${value}</a>`;
    }
  }
};

const RichText = ({ json }) => {
  let html = ReactDOMServer.renderToString(documentToReactComponents(json, options));
  html = decode(html);

  return <ReactMarkdown source={html} />;
};

export default RichText;
