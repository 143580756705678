import React, { useContext } from 'react'
import { sContext } from '../../../../context'

const CategoriesNavbar = ({
  forceShow,
  categories
}) => {
  const localePath = useContext(sContext).currentLocale

  const addedStyle = forceShow ? { display: 'block' } : null;

  return (
    <>
      <div
        className="cCategoriesNavbar"
        style={addedStyle} >
        <div className="cCategoriesNavbar-inner">
          <ul>
          {categories.map(({
            fields: {
              alt: title,
              url
          }}) => (
              <li key={`cCategoriesNavbar-${title}`}>
                <a
                  className="cCategoriesNavbar-link"
                  href={`/${localePath}/${url}/`} >
                  {title}
                </a>
                <a
                  aria-hidden
                  tabIndex='-1'
                  className='cCategoriesNavbar-link clone-after'
                  href={`/${localePath}/${url}/`} >
                  {title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default CategoriesNavbar
