import React, { useState, useEffect } from 'react'
import { sContext, contextState } from './context'
import 'core-js/features/array/includes'
import 'intersection-observer'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles/master.scss'
import config from 'react-reveal/globals';
import { PageHead } from './main-components/components/PageHead'
import { PageHeadSchema } from './lib/schemaMarkup'
import get from 'lodash.get'
import { checkForMobile } from './lib/utils'

config({ ssrReveal: true });

const App = ({
  children,
  location,
  pageContext: {
    entry,
    entryId,
    entryContentType,
    formatedLocale,
    pagePath,
    paths,
    contentfulEnv: pContentfulEnv
  }
}) => {
  const [state, setState] = useState({
    currentLocale: formatedLocale,
    topFocus: contextState.topFocus,
    pages: entry,
    pageId: entryId,
    isMobileDevice: false,
    contentfulEnv: pContentfulEnv || 'master',
  })

  useEffect(() => {
    const body = document.body
    const htmlElement = document.querySelector('html')

    body.classList.add(currentLocale)
    htmlElement.setAttribute('lang', currentLocale)
    
    body.classList.add('hide-outline')
    body.addEventListener('keydown', (event) => {
      if(body.classList.contains('hide-outline') && event.code === 'Tab') {
        body.classList.remove('hide-outline')
      }
    })

    body.addEventListener('click', () => {
      if(!body.classList.contains('hide-outline')) {
        body.classList.add('hide-outline')
      }
    })

    setState(prevState => ({
      ...prevState,
      isMobileDevice: checkForMobile()
    }))

  }, [])

  const {
    currentLocale,
    topFocus,
    isMobileDevice,
    contentfulEnv,
  } = state

  if(typeof window !== 'undefined') {
    localStorage.setItem('currentLocale', currentLocale)
  }

  const {
    template,
    title,
    socialDescription,
    socialImage,
    metaDescription,
    metaTitle,
    canonicalPath,
    sections,
    content
  } = entry

  return (
    <sContext.Provider value={{
      currentLocale,
      topFocus,
      location,
      isMobileDevice,
      contentfulEnv
    }}>
      <PageHead
        entryId={entryId}
        template={template}
        title={title}
        socialDescription={socialDescription}
        socialImage={socialImage}
        metaDescription={metaDescription}
        metaTitle={metaTitle}
        canonicalPath={canonicalPath}
        oldTopModule={get(sections, '[0].fields.subSections[0].fields', '')}
        newTopModule={content?.content?.length && content.content[0]?.data?.target?.fields}
        location={location}
        type={entryContentType}
        paths={paths}
        forceMetaTitle={pagePath === 'city-guide' || pagePath === 'guides-voyage'} />
        {children}
      <PageHeadSchema />
    </sContext.Provider>
  )
}

const buildProps = (props) => {
  const {
    location,
    pageContext: {
      layout: {
        navigation,
        footer
      },
      paths,
      entry,
      entryId,
      entryContentType,
      pagePath,
      categoryInformation,
      latestIds,
      placeOfTheDay
    }
  } = props;
  return {
    navigation,
    footer,
    entryContentType,
    paths,
    pages: entry,
    pageId: entryId,
    searchedGuides: [],
    pagePath,
    latestIds,
    categoryInformation,
    location,
    placeOfTheDay
  }
}

export { App as default, buildProps }