import React, { useContext, useEffect, useState } from 'react'
import get from 'lodash.get'
import { Helmet } from 'react-helmet'
import { getQueryVariable } from '../../lib/utils'
import defaultSocialImage from '../../images/Social/default-social.jpg'
import { sContext } from '../../context'
import {
  AD_EN,
  AD_FR,
  AD_HOME_EN,
  AD_HOME_FR,
  AD_CITY_GUIDE_EN,
  AD_CITY_GUIDE_FR,
  AD_ARTICLE_EN,
  AD_ARTICLE_FR,
  AD_AGGREGATE_EN,
  AD_AGGREGATE_FR,
  ADS
} from './Ads'
import { withPrefix } from 'gatsby-link'
import { getLandscapeAsset } from '../../lib/getAssets'

export const PageHead = ({
  template,
  title,
  socialDescription,
  socialImage,
  oldTopModule,
  newTopModule,
  metaDescription,
  location,
  metaTitle,
  entryId,
  canonicalPath,
  forceMetaTitle,
  type
}) => {
  const search = useContext(sContext).location.search
  const currentLocale = useContext(sContext).currentLocale
  const isArticle = ['Article', 'CityGuide'].includes(template) || (type && type === 'category')

  const [cookiesScriptPath, setCookiesScriptPath] = useState('')

  const getTitle = () => {
    let getTitleValue

    if (forceMetaTitle || metaTitle) {
      getTitleValue = metaTitle

    } else if (newTopModule?.imageTitle) {
      getTitleValue = newTopModule.imageTitle

    } else if (newTopModule?.subSections?.length && newTopModule.subSections[0]?.fields?.title) {
      getTitleValue = newTopModule.subSections[0].fields.title

    } else {
      let suffix
      if (template === 'Aggregate') {
        suffix = getQueryVariable('aggTag', search)
      }

      if (isArticle) {
        suffix = title
      }

      getTitleValue = `Air Canada enRoute` + (suffix ? ` | ${suffix}` : ``)
    }
    return `${getTitleValue}`.split('&nbsp;').join(' ')
  }

  const getDescription = () => {
    if (metaDescription) return metaDescription
    if (socialDescription) return socialDescription

    if (isArticle) {
      const topModule = oldTopModule || newTopModule
      return topModule.subtitle || title
    }

    return currentLocale === 'en'
      ? 'Stories that travel. Made by Air Canada.'
      : 'Des récits en mouvement. Signés Air Canada.'
  }

  const getImageUrl = () => {
    if (socialImage) {
      return `https:${get(socialImage, 'fields.file.url', defaultSocialImage)}`
    }

    if (isArticle) {
      if (newTopModule && !newTopModule?.subSections) {
        const getAsset = getLandscapeAsset({
          banner: newTopModule.image,
          overrideBanner: newTopModule.overrideBanner,
          topType: newTopModule.type,
          inArticleAsset: newTopModule.inArticleAsset,
          landscapeDesktopAsset: newTopModule.landscapeDesktopAsset,
        })

        return `https:${getAsset?.file?.url || get(oldTopModule, 'banner.fields.file.url', defaultSocialImage)}`;

      } else if (newTopModule?.subSections?.length && newTopModule.subSections[0]?.fields?.banner?.fields?.file?.url) {
        return `https:${newTopModule.subSections[0].fields.banner.fields.file.url}`

      } else if (newTopModule?.subSections?.length && newTopModule.subSections[0]?.fields?.overrideBanner?.fields?.file?.url) {
        return `https:${newTopModule.subSections[0].fields.overrideBanner.fields.file.url}`

      } else {
        return `https:${get(oldTopModule, `banner.fields.file.url`, defaultSocialImage)}`
      }
    }

    return defaultSocialImage
  }

  useEffect(() => {
    let cookiesFile

    if (location.hostname === 'localhost') {
      cookiesFile = 'launch-2198defe3641-development'

    } else if (location.hostname === 'enroute.aircanada.com') {
      cookiesFile = 'launch-ddb0f3132172'

    } else {
      cookiesFile = 'launch-2375810d7ae2-staging'
    }

    setCookiesScriptPath(`https://assets.adobedtm.com/14307738146c/aa189fb1d557/${cookiesFile}.min.js`)
  }, [location])

  return (
    <Helmet>
      <title>{metaTitle ? `${metaTitle}`.split('&nbsp;').join(' ') : getTitle()}</title>
      <meta charset="utf-8" />
      <meta
        http-equiv="Content-Type"
        content="text/html; charset=UTF-8" />
      <meta
        name="title"
        content={getTitle()} />
      <meta
        name="contentful:id"
        content={entryId} />
      <link
        rel="contentful:id:placeholder:opposite"
        data-lang={`${location && location.pathname}`.indexOf('/en') === 0 ? 'en' : 'fr'}
        data-id={entryId} />
      <link
        rel="contentful:id:placeholder:canonical"
        data-lang={`${location && location.pathname}`.indexOf('/en') === 0 ? 'en' : 'fr'}
        data-id={entryId} />
      <link
        rel="contentful:id:placeholder:self"
        data-lang={`${location && location.pathname}`.indexOf('/en') === 0 ? 'en' : 'fr'}
        data-id={entryId} />
      <meta
        name="force-rebuild"
        content={"Of all pages"} />
      <meta
        name="description"
        content={getDescription()} />

      <script src={cookiesScriptPath} async></script>

      <script>{`

        function docReady(fn) {
          // see if DOM is already available
          if (document.readyState === "complete" || document.readyState === "interactive") {
              // call on next available tick
              setTimeout(fn, 1);
          } else {
              document.addEventListener("DOMContentLoaded", fn);
          }
        }    

        docReady(function() {
          var link = document.createElement('link');
          link.as = 'style';
          link.rel="stylesheet"
          link.href = 'https://use.typekit.net/zsk5rog.css';
          document.head.appendChild(link);
        })

        
      `}</script>
      {/* Preconnect */}
      <link rel="preconnect" href="https://www15.smartadserver.com" />
      <link rel="preconnect" href="https://use.typekit.net" />


      {/* apple-touch-icon-precomposed */}
      <link
        rel="apple-touch-icon-precomposed"
        sizes="57x57"
        href="/files/favicons/apple-touch-icon-57x57.png?v=4" />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="114x114"
        href="/files/favicons/apple-touch-icon-114x114.png?v=4" />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="72x72"
        href="/files/favicons/apple-touch-icon-72x72.png?v=4" />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href="/files/favicons/apple-touch-icon-144x144.png?v=4" />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="60x60"
        href="/files/favicons/apple-touch-icon-60x60.png?v=4" />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="120x120"
        href="/files/favicons/apple-touch-icon-120x120.png?v=4" />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="76x76"
        href="/files/favicons/apple-touch-icon-76x76.png?v=4" />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href="/files/favicons/apple-touch-icon-152x152.png?v=4" />

      {/* icon */}
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/files/favicons/favicon-16x16.png?v=4" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/files/favicons/favicon-32x32.png?v=4" />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/files/favicons/favicon-96x96.png?v=4" />
      <link
        rel="icon"
        type="image/png"
        sizes="128x128"
        href="/files/favicons/favicon-128.png?v=4" />
      <link
        rel="icon"
        type="image/png"
        sizes="196x196"
        href="/files/favicons/favicon-196x196.png?v=4" />

      {/* og */}
      {canonicalPath ? <meta property="og:url" content={`https://enroute.aircanada.com${canonicalPath}`} /> : null}
      <html lang={`${location && location.pathname}`.indexOf('/en') === 0 ? 'en' : 'fr'} />
      <meta name="facebook-domain-verification" content="yinnt7vyykpxeb99aehgzla82z2bdf" />
      <meta
        property="og:title"
        content={getTitle().replace(/\*/g, '')} />
      <meta
        property="og:description"
        content={getDescription().split('*').join('_')} />
      <meta
        property="og:image"
        content={getImageUrl()} />
      {isArticle && (
        <meta
          property="og:type"
          content="article" />
      )}

      {/* twitter */}
      <meta
        name="twitter:title"
        content={getTitle()} />
      <meta
        name="twitter:description"
        content={getDescription()} />
      <meta
        name="twitter:image"
        content={getImageUrl()} />
      <meta
        name="twitter:card"
        content="summary_large_image" />

      {/* Google Tag Manager */}
      {typeof window !== 'undefined' && (
        <script>{(function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-WQXN3FCR')}</script>
      )}
      {/* End Google Tag Manager */}

      {typeof window === 'undefined' || !window._adsLoaded ? <script type="application/javascript" src="//ced.sascdn.com/tag/3520/smart.js" defer></script> : null}

      <script>{`
        var gtmContainerId = (window.location + '').indexOf('aircanada.com') === -1 ? 'GTM-XXXXXXX' : 'GTM-NCH4VSC';
        var gaPropertyId = (window.location + '').indexOf('aircanada.com') === -1 ? 'G-XXXXXXXX' : 'G-963H8ZMG70';
        window._adsLoaded = true;
      `}</script>

      <script>{`
        if (!window.dataLayerLoaded) {
          (function(){
            var script = document.createElement('script');
            script.type = 'text/javascript';
             script.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaPropertyId;
            script.async = true;
            setTimeout(function() {
              document.getElementsByTagName('head')[0].appendChild(script);
            }, 50)
          })()
          window.dataLayer = window.dataLayer || [];
          function gtag(){
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', gaPropertyId);
          window.dataLayerLoaded = true;
        }

        function offset(el) {
          var rect = el.getBoundingClientRect(),
          scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
          scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
        }
      `}</script>
      <script>{`
        window.adConfig = ${JSON.stringify({
        AD_EN,
        AD_FR,
        AD_HOME_EN,
        AD_HOME_FR,
        AD_CITY_GUIDE_EN,
        AD_CITY_GUIDE_FR,
        AD_ARTICLE_EN,
        AD_ARTICLE_FR,
        AD_AGGREGATE_EN,
        AD_AGGREGATE_FR,
        ADS
      })}
      `}</script>
      <script defer src={withPrefix('js/ads.min.js')} />

      {typeof window !== 'undefined' && window.location.hostname.indexOf('enroute.aircanada.com') === 0 ? (
        <script type="text/javascript" src="//script.crazyegg.com/pages/scripts/0010/8216.js" async="async" ></script>
      ) : null}

      {/* Dentsu Universal Analytics */}
      {typeof window !== 'undefined' && (
        window.location.pathname.includes('advertisement-lexus-canada-road-trip') ||
        window.location.pathname.includes('lexus-canada-road-trip') ||
        window.location.pathname.includes('pub-viree-lexus-canada') ||
        /** Staging Preview */
        window.location.search.includes('entry_id=4moBio9nKB9uaDxET3wquY')
      ) ? (
        <script type="text/javascript">
          {`
            !function(p,r,e,s,b,d){p.pbq||(s=p.pbq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);},s.version='1.0',s.queue=[],b=r.createElement(e),b.async=!0,b.src='https://sr.studiostack.com/v3/services',d=r.getElementsByTagName(e)[0],d.parentNode.insertBefore(b,d))}(window,document,'script');pbq('init', '250740');pbq('set', 'story');
          `}
        </script>

      ) : null}
    </Helmet>
  )
}
