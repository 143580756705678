/**
 * Ads information
 * 
 * Desktop dimentions 970 x 90
 * Mobile dimentions 320 x 50
 * 
 * The order of the ads are always AD_1 to AD_4 starting from the top.
 */

import React, { useEffect, useContext } from 'react'
import { sContext } from '../../context'

/* Site id's used for the ads */
export const AD_EN = 322988
export const AD_FR = 323449

/* Page ids used for the ads */
export const AD_HOME_EN =       1253898
export const AD_HOME_FR =       1254390
export const AD_CITY_GUIDE_EN = 1755367
export const AD_CITY_GUIDE_FR = 1755368
export const AD_ARTICLE_EN =    1253897
export const AD_ARTICLE_FR =    1254393
export const AD_AGGREGATE_EN =  1148083
export const AD_AGGREGATE_FR =  1148754

/* Ads location id */
export const ADS = {
  AD_1: 84785,
  AD_2: 84786,
  AD_3: 92060,
  AD_4: 100374
}

const Ad = ({ counter }) => {
  const id = ADS[`AD_${counter}`]
  const sasVal = `sas_${id}`

  useEffect(() => {
    if(typeof window !== 'undefined') {
      const sasA = document.querySelectorAll(`#${sasVal}`)
      if (sasA.length > 0 && window.sas !== undefined) {
        window.sas.cmd.push(() => {
          window.sas.render(id.toString())
        })
      }
    }
  }, [])
  
  return (
    <div className='ad-wrapper'>
      <div className='ad-container'>
        <div
          id={sasVal}
          className="ad-content" />
      </div>
    </div>
  )
}

const Ads = ({ counter }) => {
  const isMobileDevice = useContext(sContext).isMobileDevice

  return (
    <div className="ads-wrapper">
      <div className="ads-container">
        <div
          role="banner"
          className={`ads-content ${isMobileDevice ? 'mobile-device' : ''}`}
          aria-label='Advertisement' >
          <Ad counter={counter} />
        </div>
      </div>
    </div>
  )
}

export default Ads
