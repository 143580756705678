import React from 'react';

export const ARIA_EXTERNAL = {
  en: ', opens in a new window.',
  fr: ', ouvre dans une nouvelle fenêtre.'
}

//#region Interfaces
interface Attr_props_type {
  id?: string;
  className?: string;
  onMouseEnter?: any;
  onMouseLeave?: any;
  style?: any;
  lang?: string;
  tabIndex?: number;
  'aria-labelledby'?: string;
  'aria-hidden'?: boolean;
}

interface AnchorTag_type {
  path: string;
  localePath?: string;
  categoryPath?: string;
  articleType?: string;
  finalPath?: string;
  isAggTag?: boolean;
  attributes?: Attr_props_type;
  children: React.ReactNode;
};

interface Attr_type extends Attr_props_type {
  href: string;
  target?: string;
  rel?: string;
}
//#endregion

const AnchorTag: React.FC<AnchorTag_type> = ({
  path = '',
  localePath,
  categoryPath,
  articleType,
  finalPath,
  isAggTag,
  attributes: pAttributes = {},
  children
}) => {
  let attributes: Attr_type;

  if(path.includes('://')) {
    attributes = {
      ...pAttributes,
      href: finalPath || path,
      target: '_blank',
      rel: "noopener noreferrer"
    };
  
  } else {
    if(isAggTag) {
      attributes = {
        ...pAttributes,
        href: `/${localePath}/category/?aggTag=${articleType}`
      };

    } else {
      const adjustedPath = path === 'paris_1' ? 'paris' : path
      let formatedHref

      if(finalPath) {
        formatedHref = finalPath

      } else if(categoryPath) {
        formatedHref = `/${localePath}/${categoryPath}/${adjustedPath}/`

      } else {
        formatedHref = `/${localePath}/${adjustedPath}/`
      }

      attributes = {
        ...pAttributes,
        href: formatedHref
      };
    }
  };
  
  return (
    <a {...attributes} >
      {children}
    </a>
  );
};

export default AnchorTag;