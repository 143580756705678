import React, { memo, useContext } from 'react'
import { LinkRenderer } from '../../lib/utils'
import { sContext } from '../../context'
import upArrow from '../../images/Links/arrow-white-up.svg'
import enRouteLogo from '../../images/Logos/enRoute-white.svg'
import FooterLink from './FooterLink'
import Accordion from './Accordion'
import ReactMarkdown from '../../lib/ReactMarkdown'
import AnchorTag from './AnchorTag'

const Footer = memo(({
  footer: {
    columns,
    copyright,
    introTextUnderLogo,
    poweredBy,
    privacyPolicy: {
      fields: {
        alt: privacyText,
        url: privacyUrl
      }
    },
    socialColumn,
    socialColumnTitle
  }
}) => {
  const localePath = useContext(sContext).currentLocale

  const policyLinkAttr = {
    href: `https://www.aircanada.com/ca/${localePath}/aco/home/legal/privacy-policy.html`,
    target: '_blank',
    rel: "noopener noreferrer"
  }

  const scrollToTop = () => {
    if (typeof window !== 'undefined') { window.scrollTo(0, 0) }
    const logoLink = document.querySelector('.globalnav-logo-link')
    logoLink.focus()
  }

  return (
    <footer
      className='row footer-main'
      role='contentinfo' >
      <div className="footer-container">
        <div className="footer-toprow">
          <div className="row">
            <div className="col-sm-12 footer-totop totop-mobile">
              <button
                className="footer-totop-button"
                onClick={scrollToTop} >
                <img
                  style={{
                    aspectRatio: '1/1'
                  }}
                  src={upArrow}
                  alt={localePath === 'en' ?
                    'To top of page' : 'Retourner en haut de la page'} />
              </button>
            </div>
            <div className="col-lg-6 footer-logo">
              <a
                href="/" >
                <img
                  style={{
                    aspectRatio: '207/34'
                  }}
                  src={enRouteLogo}
                  alt={localePath === 'en' ? 'Home Page' : "Page D'accueil"} />
              </a>
              <ReactMarkdown
                renderers={{ link: LinkRenderer }}
                source={introTextUnderLogo}
                escapeHtml={false} />
            </div>
            <div className="offset-2 col-lg-4 arrow-wrapper">
              <div className="footer-totop totop-desktop">
                <button
                  className="footer-totop-button"
                  onClick={scrollToTop} >
                  <img
                    style={{
                      aspectRatio: '1/1'
                    }}
                    src={upArrow}
                    alt={localePath === 'en' ?
                      'To top of page' : 'Retourner en haut de la page'} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="links-sections">
          <div className="left-section">
            <div className="columns">
              {columns.map(({
                fields: {
                  name,
                  links
                }
              }) => (
                <div
                  className='column'
                  key={`column-${name}`} >
                  <p>{name}</p>
                  <ul>
                    {links.map((({
                      fields: {
                        alt: text,
                        url
                      }
                    }) => (
                      <FooterLink
                        text={text}
                        url={url}
                        key={`footer-Links-${text}`} />
                    )))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className="right-section">
            <div className="social-section">
              <p>{socialColumnTitle}</p>
              <ul>
                {socialColumn.map(({
                  fields: {
                    url,
                    image: {
                      fields: {
                        description,
                        file: {
                          url: image,
                          details
                        }
                      }
                    }
                  }
                }) => (
                  <li key={`footer-social-${url}`}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer" >
                      <img
                        style={{
                          aspectRatio: !details || !details.image || !details.image.height ? null : `${details.image.width} / ${details.image.height}`
                        }}
                        src={image}
                        alt={description} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="accordions">
            {columns.map(({
              fields: {
                name,
                links
              }
            }, i) => (
              <Accordion
                title={name}
                isFirst={i === 0}
                key={`footer-accordion-${name}`} >
                <ul>
                  {links.map((({
                    fields: {
                      alt: text,
                      url
                    }
                  }) => (
                    <FooterLink
                      text={text}
                      url={url}
                      key={`footer-link-${text}`}
                      isAccordion />
                  )))}
                </ul>
              </Accordion>
            ))}
          </div>
        </div>
        <div className="footer-tag">
          <AnchorTag
            path={privacyUrl}
            localePath={localePath} >
            {privacyText}
          </AnchorTag>
          <p>{`© ${copyright}`}</p>
          <ReactMarkdown
            renderers={{ link: LinkRenderer }}
            source={poweredBy}
            escapeHtml={false} />
        </div>
      </div>
    </footer>
  )
})

export default Footer