import React, { memo } from 'react';
import RichText from '../../RichText';

const PromoBanner = memo(({
  forceShow,
  promoBanner
}) => {
  const addedStyle = forceShow ? { display: 'block' } : null;

  return (
    <div
      className="promoBanner"
      style={addedStyle} >
      <div className="promoBanner-wrapper">
        <RichText json={promoBanner} />
      </div>
    </div>
  );
})

export default PromoBanner;