import { useRef, useEffect } from "react";

export default function useEventListener(eventName, handler, props = {}) {
  const savedHandler = useRef()
  const savedProps = useRef()

  useEffect(() => { savedHandler.current = handler }, [handler])
  useEffect(() => { savedProps.current = props }, [props])

  useEffect(() => {
    if(typeof window === undefined) { return }

    const eventListener = () => savedHandler.current(savedProps.current)
    window.addEventListener(eventName, eventListener)
    
    return () => {
      window.removeEventListener(eventName, eventListener)
    }
  }, [eventName])
}