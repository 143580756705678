import { checkForMobile } from "./utils";

type UnkownFields = {
  fields?: unknown;
};

type Legacy_type = {
  banner?: UnkownFields;
  image?: UnkownFields;
  overrideBanner?: UnkownFields;
};

type Landskape_type = Legacy_type & {
  topType: string;
  isEntrySection?: boolean;
  inArticleAsset?: UnkownFields;
  landscapeDesktopAsset?: UnkownFields;
  landscapeMobileAsset?: UnkownFields;
};

type Portrait_type = Legacy_type & {
  inArticleAsset?: UnkownFields;
  landscapeMobileAsset?: UnkownFields;
};

type Box_type = Legacy_type & {
  squareThumbnail?: UnkownFields;
  inArticleAsset?: UnkownFields;
};

export const getLandscapeAsset = ({
  topType,
  isEntrySection,
  banner: {
    fields: banner
  } = {},
  image: {
    fields: image
  } = {},
  overrideBanner: {
    fields: overrideBanner
  } = {},
  inArticleAsset: {
    fields: inArticleAsset
  } = {},
  landscapeDesktopAsset: {
    fields: landscapeDesktopAsset
  } = {},
  landscapeMobileAsset: {
    fields: landscapeMobileAsset
  } = {},
}: Landskape_type): unknown => {
  const isMobileDevice: boolean = checkForMobile();
  const isImageVideo = topType === 'image-video';
  let asset: unknown;

  const imageBanner = banner || image;

  if(imageBanner || overrideBanner) {
    if(isImageVideo) {
      if(isMobileDevice) {
        asset = overrideBanner || imageBanner;

      } else {
        asset = imageBanner || overrideBanner;
      };

    } else {
      if(isMobileDevice) {
        asset = imageBanner || overrideBanner;

      } else {
        asset = overrideBanner || imageBanner;
      };
    };

  } else {
    if(isImageVideo) {
      if(isMobileDevice && !isEntrySection) {
        asset = landscapeMobileAsset || inArticleAsset;
        
      } else {
        asset = inArticleAsset;
      };

    } else {
      if(isMobileDevice && !isEntrySection) {
        asset = landscapeMobileAsset || landscapeDesktopAsset;
        
      } else if(landscapeDesktopAsset) {
        asset = landscapeDesktopAsset;
        
      } else {
        asset = inArticleAsset;
      };
    };
  };

  return asset || {}
};

export const getPortraitAsset = ({
  banner: {
    fields: banner
  } = {},
  image: {
    fields: image
  } = {},
  inArticleAsset: {
    fields: inArticleAsset
  } = {},
  landscapeMobileAsset: {
    fields: landscapeMobileAsset
  } = {}
}: Portrait_type): unknown => {
  let asset;

  if(banner || image) {
    asset = banner || image;

  } else if(landscapeMobileAsset) {
    asset = landscapeMobileAsset

  } else {
    asset = inArticleAsset;
  };

  return asset || {};
};

export const getBoxAsset = ({
  banner: {
    fields: banner
  } = {},
  image: {
    fields: image
  } = {},
  overrideBanner: {
    fields: overrideBanner
  } = {},
  squareThumbnail: {
    fields: squareThumbnail
  } = {},
  inArticleAsset: {
    fields: inArticleAsset
  } = {}
}: Box_type): unknown => {
  let asset;

  const imageBanner = banner || image;

  if(squareThumbnail) {
    asset = squareThumbnail;

  } else if(imageBanner || overrideBanner) {
    asset = imageBanner || overrideBanner;
    
  } else {
    asset = inArticleAsset;
  };

  return asset || {};
};