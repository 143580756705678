import React, {
  useState,
  useEffect,
  useContext
} from 'react'
import { getContrast } from '../../../lib/utils'
import { sContext } from '../../../context'
import MainNavigation from './components/MainNavigation'
import BurgerNavigation from './components/BurgerNavigation'
import CategoriesNavbar from './components/CategoriesNavbar'
import PromoBanner from './components/PromoBanner'
import SearchNavigation from './components/SearchNavigation'

const Navigation = ({
  navigationColor,
  default: defaultFontColor = 'black',
  menu,
  backgroundColor,
  color: fontColor,
  forceShowCategories,
  paths,
  topFocus,
  hasTopAd,
  isHomepage
}) => {
  const location = useContext(sContext).location
  const localePath = useContext(sContext).currentLocale
  const path = location.pathname
  const [navTypeShown, setNavTypeShown] = useState('initial');
  const [colors, setColors] = useState({
    finalBackgroundColor: null,
    finalFontColor: null
  })

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const body = document.getElementsByTagName('body')[0]
      body.style.overflow = navTypeShown === 'initial' ? 'initial' : 'hidden'
    }
  }, [navTypeShown])

  const activateNavType = (type) => {
    setNavTypeShown(type)
  }

  useEffect(() => {
    let finalFontColor
    let finalBackgroundColor

    if (navigationColor && navigationColor !== '/') {
      if (navigationColor === 'white') {
        finalBackgroundColor = '#ffffff'
      } else if (navigationColor === 'black') {
        finalBackgroundColor = '#1C1C1C'
      } else if (navigationColor === 'red') {
        finalBackgroundColor = '#ae1722'
      } else if (navigationColor === 'pink') {
        finalBackgroundColor = '#ffe8e0'
      }

      finalFontColor = getContrast(finalBackgroundColor)
    } else {
      if (backgroundColor) {
        finalBackgroundColor = backgroundColor
        finalFontColor = fontColor
      } else {
        finalBackgroundColor = '#ffffff'
        finalFontColor = 'black'
      }
    }

    setColors({
      finalBackgroundColor,
      finalFontColor,
    })
  }, [navigationColor])

  const getLanguageChange = (type) => {
    if (localePath === type) return
    const {
      pathname,
      search
    } = location
    let newPathname

    const searchPath = `/${type === 'en' ? 'fr' : 'en'}/search`

    if (pathname === searchPath || pathname === `${searchPath}/`) {
      newPathname = `/search${search}`

    } else if (paths && paths[type]) {
      return paths[type];
    }
    return `/${type}${newPathname || ''}/`
  }

  const tempColorAdj = (isTop10) => {
    let finalColors

    if (isTop10) {
      finalColors = defaultFontColor

    } else if (
      colors.finalFontColor === 'black' ||
      path.includes('/city-guide/') ||
      path.includes('/guides-voyage/')
    ) {
      finalColors = 'black-black'

    } else if (colors.finalFontColor === 'white') {
      finalColors = 'white-white'
    }

    return finalColors
  }

  const isTop10 = path.includes('/top-10/')

  const finalColorsClass = tempColorAdj(isTop10) || null

  return (
    <header role='navigation'>
      <div
        aria-hidden={navTypeShown !== 'initial'}
        className={
          `row globalnav-main ${isTop10 ? 'is-top-10' : ''} ${finalColorsClass || ''} ${forceShowCategories ? 'is-narrow' : ''} ${navTypeShown === 'initial' ? 'show-visibility' : 'hide-main'}
        `} >
        <MainNavigation
          isTop10={isTop10}
          isHomepage={isHomepage}
          topFocus={topFocus}
          activateNavType={activateNavType}
          getLanguageChange={getLanguageChange}
          isInitialNavType={navTypeShown === 'initial'}
          finalColorsClass={finalColorsClass}
          hasTopAd={hasTopAd}
          colors={colors} />
      </div>

      <div
        key="nav-02"
        aria-hidden={navTypeShown !== 'menu'}
        className={`
          row globalnav-burger
          ${navTypeShown === 'menu' ? 'show-hidden' : ''}
          ${navTypeShown === 'menu' ? 'show-visibility' : ''}
        `} >
        <BurgerNavigation
          menu={menu}
          activateNavType={activateNavType}
          getLanguageChange={getLanguageChange} />
      </div>
      <div
        key="nav-03"
        aria-hidden={navTypeShown !== 'search'}
        className={`
          row globalnav-searchmodal
          ${navTypeShown === 'search' ? 'show-hidden' : ''}
          ${navTypeShown === 'search' ? 'show-visibility' : ''}
        `} >
        <SearchNavigation
          isSearchNavType={navTypeShown === 'search'}
          guides={menu.guides}
          activateNavType={activateNavType}
          getLanguageChange={getLanguageChange} />
      </div>
      <CategoriesNavbar
        forceShow={forceShowCategories}
        categories={menu.categoriesNavbar} />
      {menu.promoBanner && (
        <PromoBanner
          forceShow={forceShowCategories}
          promoBanner={menu.promoBanner} />
      )}
    </header>
  )
}

export default Navigation