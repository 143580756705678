import ReactMarkdown from 'react-markdown'
const xtend = require('xtend')

export default function (props) {
  const oldRenderers = (props && props.renderers) || {}
  const newProps = xtend(props, {
    renderers: {
      ...oldRenderers,
      text: (node) => {
        return `${node.value}`.replace(/-/g,'\u2013')
      }
    }
  })
  return ReactMarkdown(newProps)
}