import React, {
  useRef,
  useState
} from 'react'
import { svgs } from '../../lib/utils'

const Accordion = ({
  title,
  children,
  isFirst
}) => {
  const content_ref = useRef()
  const [expanded, setExpanded] = useState(false)

  const handleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <div className={`
      footer-accordion
      ${isFirst ? 'is-first' : ''}
    `} >
      <div
        className='footer-accordion-title'
        onClick={handleExpand} >
        <p>{title}</p>
        {svgs[expanded ? 'minus' : 'plus']}
      </div>
      <div
        className={`
          footer-accordion-content
        `}
        style={{ maxHeight: `${expanded ? content_ref.current.clientHeight : 0}px` }} >
        <div ref={content_ref}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Accordion