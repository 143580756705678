import React, { useContext, useRef, useState } from 'react'
import { sContext } from '../../context'

const FooterLink = ({
  text,
  url,
  isAccordion
}) => {
  const localePath = useContext(sContext).currentLocale
  const anchor_ref = useRef()
  const [underlineWidth, setUnderlineWidth] = useState(0)

  let newUrl = url
  let isExternal = false
  if(url.includes('@aircanadaenroute')) {
    newUrl = `mailto:${url}`
    isExternal = true
    
  } else if(url.includes('http')) {
    isExternal = true
  
  } else {
    newUrl = `/${localePath}/${(url.indexOf('category?') !== -1 ? url.split('category?').join('category/?') : (url[url.length - 1] !== '/' ? url + '/' : url))}`
  }

  const handleHover = (hover) => {
    if(isAccordion) { return }
    if(hover) {
      const width = anchor_ref.current.offsetWidth
      setUnderlineWidth(width)
    } else {
      setUnderlineWidth(0)
    }
  }
  
  return (
    <li>
      {isExternal ? (
        <a
          ref={anchor_ref}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          href={newUrl}
          target='_blank'
          rel="noopener noreferrer" >
          {text}
        </a>
      ) : (
        <a
          href={newUrl}
          ref={anchor_ref}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)} >
          {text}
        </a>
      )}
      {!isAccordion && (
        <div
          className='footer-link-underline'
          style={{ width: `${underlineWidth}px` }} />
      )}
    </li>
  )
}

export default FooterLink