import React, { Fragment, useContext, useEffect, useState } from 'react'
import { sContext } from '../../../../context'
import ReactMarkdown from '../../../../lib/ReactMarkdown'
import AnchorTag from '../../AnchorTag'
import { CloseButton, EnFrButton, SearchButton } from './NavigationHelpers'
import { LinkRenderer } from '../../../../lib/utils'

const BurgerNavigation = ({
  menu,
  activateNavType,
  getLanguageChange
}) => {
  const localePath = useContext(sContext).currentLocale
  const [groupedLinks, setGroupedLinks] = useState([])
  const [nestingMap, setNestingMap] = useState({})

  useEffect(() => {
    const tempGroupedLinks = []
    let currentCluster = [];

    menu.links.forEach(link => {
      if (link.fields.isLeading) {
        if (currentCluster.length > 0) {
          tempGroupedLinks.push({ type: 'cluster', links: [...currentCluster] });
          currentCluster = [];
        }

        tempGroupedLinks.push({ type: 'is-leading', links: [link.fields] });

      } else {
        currentCluster.push(link.fields);
      }
    });

    if (currentCluster.length > 0) {
      tempGroupedLinks.push({ type: 'cluster', links: [...currentCluster] });
    }

    setGroupedLinks(tempGroupedLinks)

    const tempNestingMap = {}

    if(menu.nestingReferences) {
      menu.nestingReferences.forEach(({fields: reference}) => {
        tempNestingMap[reference.parentLink.fields.text] = reference.links
      })
    }

    setNestingMap(tempNestingMap)
  }, [])

  return (
    <>
      <div
        className="col-lg-8 globalnav-blank"
        onClick={() => activateNavType('initial')} >
        <a
          tabIndex='1'
          aria-labelledby='mobile-home-page-button'
          href="/" >
          <div className="globalnav-logo" />
          <span
            hidden
            id='mobile-home-page-button' >
            {localePath === 'en' ? 'Home Page' : "Page D'accueil"}
          </span>
        </a>
      </div>

      <div className="col-lg-4 globalnav-nav">
        <div className="globalnav-tools-container">
          <div className="row globalnav-tools white-white">
            <SearchButton
              ariaId='search-button-2'
              localePath={localePath}
              activateNavType={activateNavType} />
            <EnFrButton
              english='EN'
              french='FR'
              idNumber='2'
              getLanguageChange={getLanguageChange}
              localePath={localePath} />
            <CloseButton
              ariaId='close-button-1'
              ariaTitle={localePath === 'en' ? 'Close Menu Window' : 'Fermer la fenêtre du menu'}
              activateNavType={activateNavType} />
          </div>
        </div>
        <div className="globalnav-tagline">
          <EnFrButton
            english='ENGLISH'
            french='FRENCH'
            addedClass='mobile'
            idNumber='3'
            getLanguageChange={getLanguageChange}
            localePath={localePath} />
          <ReactMarkdown
            source={menu.text}
            renderers={{ link: LinkRenderer }} />
        </div>
        <div className="globalnav-menulist">
          {groupedLinks.map((group, index) => (
            <div
              key={`groupedNav-${index}`}
              className={group.type} >
              {group.links.map((link, linkIndex) => (
                <Fragment key={`groupedNav-${index}-${linkIndex}`}>
                  <div className="globalnav-link">
                    <AnchorTag
                      path={link.url}
                      localePath={localePath} >
                      {link.alt}
                    </AnchorTag>
                  </div>
                  {nestingMap[link.text] && Object.keys(nestingMap[link.text]).map((key, thirdIndex) => {
                    const { fields: subLink } = nestingMap[link.text][key];
                    return (
                      <div
                        key={`groupedNav-${index}-${linkIndex}-${thirdIndex}`}
                        className="globalnav-link nested" >
                        <AnchorTag
                          path={subLink.url}
                          localePath={localePath} >
                          {subLink.alt}
                        </AnchorTag>
                      </div>
                    )
                  })}
                </Fragment>
              ))}
            </div>
          ))}
        </div>
        {menu.collections && (
          <div className="globalnav-collections">
            <div className="globalnav-title">Collections</div>
            <div className="globalnav-link">
            </div>
            {menu.collections.map(({
              fields: {
                alt: title,
                url
              }
            }, index) => (
              <div
                key={`globalnav-collections-${index}`}
                className="globalnav-link">
                <a
                  href={url} >
                  {title}
                </a>
              </div>
            ))}
          </div>
        )}
        <div className="globalnav-topics">
          <div className="globalnav-title">
            {localePath === 'en' ? 'Topics' : 'Sections'}
          </div>
          {menu.topics.map((topic) => (
            <div
              key={`globalnav-title-${topic}`}
              className="globalnav-link" >
              {' '}
              <a href={`/${localePath}/category/?aggTag=${topic}`}>
                {' '}{topic}{' '}
              </a>
              {' '}
            </div>
          ))}
        </div>
        <div className="globalnav-tagline mobile">
          <ReactMarkdown
            source={menu.text}
            renderers={{ link: LinkRenderer }} />
        </div>
      </div>
    </>
  )
}

export default BurgerNavigation