import React, { useContext } from 'react'
import { sContext } from '../../context'
import ReactMarkdown from '../../lib/ReactMarkdown'

const CityGuides = ({
  cityguides,
  text,
  isFirstSection,
  categoryInformation
}) => {
  const localePath = useContext(sContext).currentLocale

  return (
    <div className="row cityguides-guides cityguides-fullwidth">
      {isFirstSection && (
        <>
          <h1 className='latest-title city-guide'>
            {categoryInformation.title}
          </h1>
          {categoryInformation && (
            <ReactMarkdown source={categoryInformation.description} />
          )}
        </>
      )}
      <div className="cityguides-whereto">
        <h2 className="cityguides-title">
          {text.split('\n')[0]}
        </h2>

        <ul
          id="cityguideLinks"
          className="cityguides-link"
        >
          {cityguides
            .filter(item => item.hasOwnProperty('fields'))
            .sort(function(a, b) {
              return a.fields.title.split(' - ')[1] >
                b.fields.title.split(' - ')[1]
                ? 1
                : -1
            })
            .map((data, index) => (
              <li
                key={'cityguide-link-' + index}
                className="cityguides-links" >
                <a href={'/' + localePath + `/${localePath === 'en' ? 'city-guide' : 'guides-voyage'}/` + data.fields.path + '/'}>
                  {' '}
                  {data.fields.title.split(' - ')[1]}{' '}
                </a>
              </li>
            ))
          }
        </ul>
      </div>
    </div>   
  )
}

export default CityGuides
