import React, { useContext, useEffect, useRef } from 'react'
import { sContext } from '../../../../context'
import {
  EnFrButton,
  MainLogo,
  SearchButton
} from './NavigationHelpers'
import useEventListener from '../../../../hooks/useEventListener'

const MainNavigation = ({
  isTop10,
  isHomepage,
  activateNavType,
  getLanguageChange,
  isInitialNavType,
  finalColorsClass,
  hasTopAd,
  colors
}) => {
  const mainRef = useRef()
  const localePath = useContext(sContext).currentLocale

  const handleScroll = ({
    bgColor,
    fFontColor
  }) => {
    if (!mainRef.current) return

    let offSetTop
    if(hasTopAd) {
      offSetTop = document.querySelector('.main-wrapper').offsetTop
    }

    const isSticking = offSetTop ? window.scrollY > offSetTop : !!window.scrollY

    if(isSticking) {
      mainRef.current.style.backgroundColor = bgColor
      mainRef.current.classList.remove(finalColorsClass)
      mainRef.current.classList.add(fFontColor)
      
    } else {
      mainRef.current.style.backgroundColor = null
      mainRef.current.classList.remove(fFontColor)
      mainRef.current.classList.add(finalColorsClass)
    }
  }

  useEventListener('scroll', handleScroll, {
    bgColor: colors.finalBackgroundColor,
    fFontColor: colors.finalFontColor
  })

  const homepageAriaCopy = () => {
    if(isTop10) {
      if(localePath === 'en') {
        return 'Canadas Best New Restaurants'
      } else {
        return 'Canadas Meilleurs Nouveaux Restaurants'
      }
  
    } else {
      if(localePath === 'en') {
        return 'Home Page'
      } else {
        return "Page D'accueil"
      }
    }
  }

  useEffect(() => {
    if(isInitialNavType) {
      if(typeof window !== 'undefined' && window.scrollY) {
        mainRef.current.style.backgroundColor = colors.finalBackgroundColor
        mainRef.current.classList.remove(finalColorsClass)
        mainRef.current.classList.add(colors.finalFontColor)
        
      } else {
        mainRef.current.style.backgroundColor = null
        mainRef.current.classList.remove(colors.finalFontColor)
        mainRef.current.classList.add(finalColorsClass)
      }
    }
  }, [isInitialNavType, colors])

  return (
    <div className="globalnav-main-wrapper">
      <div ref={mainRef} className="globalnav-main-container">
        <MainLogo
          isHomepage={isHomepage}
          className='col-lg-3' >
          <a
            className='globalnav-logo-link'
            aria-labelledby='home-page-button'
            href={
              isTop10
                ? `/canadas-best-new-restaurants/${localePath}`
                : `/${localePath}/`
            } >
            <div className={'globalnav-logo' + ((isTop10 && '-cbnr') || '')} />
            <span
              hidden
              id='home-page-button' >
              {homepageAriaCopy()}
            </span>
          </a>
        </MainLogo>
        <div className="globalnav-main-content col-lg-4 offset-5">
          <div
            className="row"
            style={{ height: '100%' }} >
            <SearchButton
              ariaId='search-button-1'
              localePath={localePath}
              activateNavType={activateNavType} />
            <EnFrButton
              english='EN'
              french='FR'
              idNumber='1'
              getLanguageChange={getLanguageChange}
              localePath={localePath} />
            <button
              aria-labelledby='hamburger-menu'
              className="col-lg-2 globalnav-menu"
              onClick={() => activateNavType('menu')}>
              <span
                hidden
                id="hamburger-menu" >
                {localePath === 'en' ? 'Open Menu Window' : 'Ouvrir la fenêtre du menu'}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainNavigation