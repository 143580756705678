import React from 'react';

export const MainLogo = ({
  isHomepage,
  className,
  children
}) => {
  if(isHomepage) {
    return (
      <h1 className={className}>
        {children}
      </h1>
    );

  } else {
    return (
      <div className={className}>
        {children}
      </div>
    );
  };
};

export const SearchButton = ({
  ariaId,
  close,
  localePath,
  activateNavType
}) => {

  let ariaCopy;
  if(localePath === 'en') {
    
    if(close) {
      ariaCopy = 'Close Search window'

    } else {
      ariaCopy = 'Open Search window'
    };
    
  } else {
    if(close) {
      ariaCopy = 'Fermer la fenêtre de recherche'

    } else {
      ariaCopy = 'Ouvrir la fenêtre de recherche'
    };
  };

  return (
    <button
      aria-labelledby={ariaId}
      className="col-lg-2 offset-1 globalnav-search"
      onClick={() => {
        const type = close ? 'initial' : 'search'
        activateNavType(type)
      }} >
      <span
        hidden
        id={ariaId} >
        {ariaCopy}
      </span>
    </button>
  );
};

export const EnFrButton = ({
  english,
  french,
  addedClass = '',
  idNumber,
  getLanguageChange,
  localePath
}) => (
  <div className={`col-lg-4 globalnav-enfr ${addedClass}`} >
    <a
      lang='en'
      href={getLanguageChange('en')}
      aria-labelledby={`enfr-${idNumber}`}
      tabIndex={localePath === 'en' ? '-1' : '0'} >
      <span
        id={`enfr-${idNumber}`}
        hidden >
        Switch language to english.
      </span>
      <span aria-hidden='true'>
        {english}
      </span>
    </a>
    <span aria-hidden>
      &nbsp;/&nbsp;
    </span>
    <a
      lang='fr'
      href={getLanguageChange('fr')}
      aria-labelledby={`enfr-${idNumber}${idNumber}`}
      tabIndex={localePath === 'fr' ? '-1' : '0'} >
      <span
        id={`enfr-${idNumber}${idNumber}`}
        hidden >
        Changez la langue en français.
      </span>
      <span aria-hidden='true'>
        {french}
      </span>
    </a>
  </div>
);

export const CloseButton = ({
  ariaId,
  ariaTitle,
  activateNavType
}) => (
  <button
    aria-labelledby={ariaId}
    className="col-lg-2 globalnav-close"
    onClick={() => activateNavType('initial')} >
    <span
      hidden
      id={ariaId} >
      {ariaTitle}
    </span>
  </button>
);