import React from 'react'

export const BylineSchema = ({
  date,
  author,
  aggTags
}) => {

  const obj = {
    "@context": "https://schema.org",
    "@type": "Article"
  }

  const monthsHashEn = {
    january: 1,
    february: 2,
    march: 3,
    april: 4,
    may: 5,
    june: 6,
    july: 7,
    august: 8,
    september: 9,
    october: 10,
    november: 11,
    december: 12
  }

  const monthsHashFr = {
    janvier: 1,
    février: 2,
    mars: 3,
    avril: 4,
    mai: 5,
    juin: 6,
    juillet: 7,
    août: 8,
    septembre: 9,
    octobre: 10,
    november: 11,
    décembre: 12
  }

  if(aggTags) {
    obj.keywords = aggTags.join(',')
  }

  try {
    if(date) {
      let badDate, year, month, day

      for (let i = 0; i < date.split(' ').length; i++) {
        const item = date.split(' ')[i];

        const yearRegex = new RegExp(/^[0-9]{4}$/g)
        const dayRegex = new RegExp(/^[0-9]{2}$/g)

        if(yearRegex.test(item)) {
          year = item

        } else if(monthsHashEn[item.toLowerCase()] || monthsHashFr[item.toLowerCase()]) {
          month = monthsHashEn[item.toLowerCase()] || monthsHashFr[item.toLowerCase()]

        } else if (dayRegex.test(parseInt(item))) {
          day = parseInt(item)

        } else {
          badDate = true
          break
        }
      }
    
      if(!badDate) {
        obj.datePublished = `${year}-${month}-${day}`
      }
    }
  } catch(_) {}

  if(author) {
    obj.author = {
      "@type": "Person",
      name: author
    }
  }

  const json = JSON.stringify(obj)
  
  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{__html: json}}/>
  )
}

export const ArticleSchema = ({
  type,
  headline,
  about
}) => {

  const obj = {
    "@context": "https://schema.org",
    "@type": "Article",
    articleSection: type,
    headline: headline,
    about: {
      "@type": "Thing",
      name: about
    }
  }

  const json = JSON.stringify(obj)

  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{__html: json}}/>
  )
}

export const PageHeadSchema = () => {

  const obj = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "https://enroute.aircanada.com",
    logo: "https://enroute.aircanada.com/files/images/schema-logo.png",
    description: "Air Canada enRoute magazine is a source for trip inspiration, travel tips, expertly curated itineraries, must-read interviews and food worth flying for.",
    email: "info@aircanadaenroute.com",
    parentOrganization: {
      "@type": "Organization",
      legalName: "Air Canada"
    },
    subOrganization: {
      "@type": "Organization",
      legalName: "enRoute"
    }
  }

  const json = JSON.stringify(obj)

  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{__html: json}}/>
  )
}