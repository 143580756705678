import React from 'react'
import { contentfulImagesSettings } from './settings'

let hasWebp = false
;(function() {
  return new Promise(res => {
    if(typeof window !== 'undefined') {
      const webP = new Image()
      webP.src =
        'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA'
      webP.onload = webP.onerror = function() {
        hasWebp = webP.height === 2
      }
    }
  })
})()

export const getQueryVariable = (variable, search = null) => {
  const urlParams = new URLSearchParams(search || (typeof window !== 'undefined' ? window.location.search : ''))
  return urlParams.get(variable) === null ? false : urlParams.get(variable)
}

export const calcRatio = (divWidth, originalWidth, originalHeight) => {
  const newHeight = (originalHeight / originalWidth) * divWidth
  return parseInt(newHeight)
}

export const decorateImagesParams = (url, overrides = {}) => {
  if (!url || !url.indexOf) return ''
  if (url.indexOf('//images.ctfassets.net/') === -1) return url

  const newParams = {
    ...contentfulImagesSettings,
    ...overrides
  }

  if (url.indexOf('.gif') > -1) newParams.fm = '' // != override gif (could be animated)
  if (url.indexOf('.gif') !== -1) {
    newParams.q = 60
    newParams.w = newParams.w > 1640 ? 1640 : newParams.w
  }
  newParams.fm = hasWebp ? 'webp' : newParams.fm
  const fl = newParams.fm === 'jpg' ? '&fl=progressive' : ''
  return `${url}?q=${newParams.q}&fm=${newParams.fm}&w=${newParams.w}${fl}`
}

export const getCurrentPagePath = () => {
  if(typeof window !== 'undefined') {
    const path = window.location.pathname.split('/').filter(v => v).slice(-1)[0]
    if (path === 'en' || path === 'fr') return ''
    return path.replace(/\/$/g, '').toLowerCase()
  } else {
    return ''
  }
}

export const getIsPreview = () => {
  return getQueryVariable('preview') !== false
}

export const getCategoryPath = ({ template }) => {
  if (template === 'Article' || template === 'CityGuide') {
    return `/${template.toLowerCase()}`
  }
  if (template === 'MobileArticle') {
    return '/article'
  }

  if (template === 'CBNR Top 10') {
    return '/top-10'
  }

  return ''
}

export const LinkRenderer = props => {
  if (props.href.includes('enroute.aircanada.com') || props.href.includes('213a.studio') || props.href.includes('localhost')) {
    return (
      <a href={props.href} rel="noopener noreferrer">
        {props.children}
      </a>
    )
  } else {
    return (
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    )
  }
}

export const getContrast = hexcolor => {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === '#') {
    hexcolor = hexcolor.slice(1)
  }

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split('')
      .map(hex => hex + hex)
      .join('')
  }

  // Convert to RGB value
  const r = parseInt(hexcolor.substr(0, 2), 16)
  const g = parseInt(hexcolor.substr(2, 2), 16)
  const b = parseInt(hexcolor.substr(4, 2), 16)

  // Get YIQ ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000

  // Check contrast
  return yiq >= 128 ? 'black' : 'white'
}

export const fullColorHex = rgb => {
  let colors = rgb.split('rgb(')[1]
  colors = colors.split(')')[0]
  colors = colors.split(',')

  let splitHex = ['#']
  for (let i = 0; i < colors.length; i++) {
    const color = colors[i]
    let hex = Number(color).toString(16)
    if (hex.length < 2) {
      hex = '0' + hex
    }

    splitHex.push(hex)
  }

  return splitHex.join('')
}

export const makeFullHexcode = hex => {
  if (hex.length === 7) {
    return hex
  }

  let splitHex = ['#']
  let colors = hex.split('#')[1]
  colors = colors.split('')
  for (let i = 0; i < colors.length; i++) {
    const color = colors[i]
    splitHex.push(color + color)
  }
  return splitHex.join('')
}

export const colorPicker = color => {
  if        (color === 'white') { return '#ffffff' }
  else if   (color === 'black') { return '#1C1C1C' }
  else if   (color === 'red')   { return '#ae1722' }
  else if   (color === 'pink')  { return '#ffe8e0' }
  else                          { return null }
}

export const formatArticleElement = (element, forceBanner) => {
  const { sys: { contentType: { sys: {
    id
  }}}} = element

  if(id !== 'article' && id !== 'page') { return undefined }

  if(id === 'article') {
    const {
      path,
      banner,
      overrideBanner,
      type,
      title,
      subtitle,
      sponsored_by
    } = element.fields

    const setImage = !forceBanner && overrideBanner ? overrideBanner : banner

    return {
      path,
      type,
      title,
      subtitle,
      banner: setImage,
      elementType: id,
      sponsored_by
    }

  } else if(id === 'page') {
    const {
      path,
      tags,
      articleType,
      category,
      content: { content: [{ data: { target: { fields: {
        overrideBanner,
        image: banner,
        inArticleAsset,
        landscapeDesktopAsset,
        imageTitle: title,
        subtitle,
      }}}}]}
    } = element.fields

    const setImage = !forceBanner && overrideBanner ? overrideBanner : banner

    return {
      path,
      tags,
      title,
      subtitle,
      type: articleType,
      banner: setImage,
      elementType: id,
      category,
      inArticleAsset,
      landscapeDesktopAsset
    }
  }
}

export const svgs = {
  plus: (
    <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
  ),
  minus: (
    <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' width="24" height="24" viewBox="0 0 24 24"><path d="M0 10h24v4h-24z"/></svg>
  )
}

export const checkForMobile = () => {
  if(typeof navigator === 'undefined') return false
  const ua = navigator.userAgent;
  return (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua) ||
    /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)
  );
};